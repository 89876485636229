import React, { ForwardedRef, forwardRef } from "react";

import classnames from "classnames/bind";

import { DropzoneInputProps } from "react-dropzone";

import styles from "./BorderedFileContainer.module.scss";

const cx: CX = classnames.bind(styles);

const BorderedFileContainer = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { isDragAccept, isDragReject, children, ...rest } = props;

    return (
        <div
            ref={ref}
            className={cx("bordered-file-container", {
                "is-drag-accept": isDragAccept,
                "is-drag-reject": isDragReject,
            })}
            {...rest}
        >
            <div className={cx("base")}>
                <div className={cx("input-container")}>{children}</div>
            </div>
        </div>
    );
});

BorderedFileContainer.defaultProps = {
    isDragAccept: false,
    isDragReject: false,
};

BorderedFileContainer.displayName = "BorderedFileContainer";

type Props<T extends DropzoneInputProps = DropzoneInputProps> = {
    isDragAccept?: boolean;
    isDragReject?: boolean;
} & T &
    ChildrenProps;

export default BorderedFileContainer;
