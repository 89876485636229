import React, { useLayoutEffect, useState } from "react";

import classnames from "classnames/bind";

import { SubmitFileSuccess } from "@API/mutations";

import { H1 } from "@VIEW/components/basic/titles";
import { IgnoreList, LoginView } from "@VIEW/components/common";
import { EmailFormValues } from "@VIEW/components/forms";
import { EmailStep, FileStep } from "@VIEW/components/steps";

import styles from "./Home.module.scss";
import { useCurrentUser } from "@VIEW/hooks/useCurrentUser";
import AuthenticationView from "@VIEW/components/common/AuthenticationView";

const cx: CX = classnames.bind(styles);

const DEFAULT_FORCE_UPLOAD = true;

function IndexComponent() {
    const [permissionDenied, setPermissionDenied] = useState<boolean>(false);
    const [step, setStep] = useState<"email" | "file">("email");

    const [forceUpload, setForceUpload] = useState<boolean>(DEFAULT_FORCE_UPLOAD);
    const [email, setEmail] = useState<string>("");

    const { email: currentUserEmail, isAuthentication } = useCurrentUser();

    function handleSubmitEmail(values: EmailFormValues) {
        setForceUpload(values.forceUpload);
        setEmail(values.email);
        setStep("file");
    }

    function handleSubmitFileSuccess(_: SubmitFileSuccess) {
        //
    }

    function handleSubmitFileError(_: Error) {
        //
    }

    function handleFileProcessingComplete() {
        setForceUpload(DEFAULT_FORCE_UPLOAD);
        setEmail(currentUserEmail ?? "");
        setStep("email");
    }

    useLayoutEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.token !== "1Ct4Ldb7G7PWIgJ2j8vqAi") {
            setPermissionDenied(true);
        }
    }, []);

    if (permissionDenied) {
        return <LoginView />;
    }

    if (isAuthentication) {
        return <AuthenticationView />;
    }

    return (
        <div className={cx("home")}>
            <div className={cx("main")}>
                <H1 title="OASIS File Upload to COMPASS EMEA" />

                {step === "email" && (
                    <EmailStep
                        defaultEmail={currentUserEmail || ""}
                        defaultForceUpload={forceUpload}
                        onSubmit={handleSubmitEmail}
                    />
                )}

                {step === "file" && (
                    <FileStep
                        forceUpload={forceUpload}
                        email={email}
                        onSubmitSuccess={handleSubmitFileSuccess}
                        onSubmitError={handleSubmitFileError}
                        onComplete={handleFileProcessingComplete}
                    />
                )}
            </div>
            <div className={cx("ignore-list-wrapper")}>
                <IgnoreList />
            </div>
        </div>
    );
}

export default IndexComponent;
