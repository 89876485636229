import { API_PREFIX, BASE_URL } from "@CORE/constants";

export type DeleteIgnoredFieldParams = {
    id: number;
};

export type DeleteIgnoredFieldSuccess = {
    // TODO ?
};

export type DeleteIgnoredFieldError = {
    // TODO error
};

export async function deleteIgnoredFieldFn(params: DeleteIgnoredFieldParams) {
    const { id } = params;

    const response = await fetch(BASE_URL + API_PREFIX + `/ignore/delete/${id}`, {
        method: "DELETE",
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}
