import React from "react";

import classnames from "classnames/bind";

import { ToastContainer } from "react-toastify";

import styles from "./Notificator.module.scss";

const cx: CX = classnames.bind(styles);

function Notificator() {
    return (
        <ToastContainer
            className={cx("notificator")}
            position="top-right"
            autoClose={10000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            bodyStyle={{
                fontSize: 12,
                lineHeight: 1.5,
                whiteSpace: "pre-line",
            }}
            pauseOnHover
        />
    );
}

export default Notificator;
