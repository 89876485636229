import React from "react";

import classnames from "classnames/bind";

import styles from "./H1.module.scss";

const cx: CX = classnames.bind(styles);

function H1(props: Props) {
    const { title } = props;

    return <div className={cx("h1")}>{title}</div>;
}

type Props = {
    title: string;
};

export default H1;
