import { XOR } from "ts-xor";

import { API_PREFIX, BASE_URL } from "@CORE/constants";

type SuccessWithData = {
    id: number;
    isFinished: boolean;
    progress: number;
    initiatedBy: string;
    fileName: string;
    scheduledAt: string | null;
};
type SuccessEmptyData = object;

export type GetProgressSuccess = XOR<SuccessWithData, SuccessEmptyData>;

export type GetProgressError = {
    // TODO error
};

export function isProgressSuccessWithData(data: GetProgressSuccess): data is SuccessWithData {
    return data.id !== undefined;
}

export async function getProgressFn() {
    const response = await fetch(BASE_URL + API_PREFIX + "/progress");

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}
