import React from "react";

import classnames from "classnames/bind";

import { FileWithPath, useDropzone } from "react-dropzone";

import { ReactComponent as WarningCircleIcon } from "@ICONS/warning-circle-icon.svg";

import { BorderedFileContainer } from "@VIEW/components/common";

import styles from "./DropFileInput.module.scss";

const cx: CX = classnames.bind(styles);

function DropFileInput(props: Props) {
    const { title, onDrop } = props;

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        multiple: false,
        accept: {
            "text/csv": [],
        },
        onDrop: (acceptedFiles: FileWithPath[]) => {
            onDrop(acceptedFiles);
        },
    });

    return (
        <div className={cx("drop-file-input")}>
            <BorderedFileContainer
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                {...getRootProps({ style: { cursor: "pointer" } })}
            >
                <div className={cx("content")}>
                    <input {...getInputProps()} />
                    {isDragReject ? (
                        <>
                            <div className={cx("reject-icon")}>
                                <WarningCircleIcon />
                            </div>
                            <div className={cx("reject-message")}>
                                Wrong file format, please try to upload new file.
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={cx("title")}>{title}</div>
                            <div className={cx("or")}>or</div>
                        </>
                    )}

                    <div className={cx("fake-button")}>Choose file</div>
                </div>
            </BorderedFileContainer>
        </div>
    );
}

type Props = {
    title: string;
    onDrop: (acceptedFiles: Array<FileWithPath>) => void;
};

export default DropFileInput;
