import React, { useEffect, useState } from "react";

import classnames from "classnames/bind";

import { useMutation } from "@tanstack/react-query";

import { SubmitFileParams, SubmitFileSuccess, submitFileFn } from "@API/mutations";

import { FileConfirm, FileItem, NotesPair, Report } from "@VIEW/components/common";
import { FileForm } from "@VIEW/components/forms";

import { useFileProcessing, useNotifications } from "@VIEW/hooks";

import styles from "./FileStep.module.scss";

const cx: CX = classnames.bind(styles);

function Parent(props: { email: string } & ChildrenProps) {
    const { email, children } = props;

    return (
        <div className={cx("file-step")}>
            <Report email={email} />

            {children}

            <NotesPair />
        </div>
    );
}

function FileStep(props: Props) {
    const { forceUpload, email, onSubmitSuccess, onSubmitError, onComplete } = props;

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [waitFinishProcessing, setWaitFinishProcessing] = useState<boolean>(false);

    const { notify } = useNotifications();

    const {
        mutate: submit,
        isLoading,
        isSuccess,
    } = useMutation<SubmitFileSuccess, Error, SubmitFileParams>({
        mutationFn: submitFileFn,
        onSuccess: onSubmitSuccess,
        onError: (error: Error, variables: SubmitFileParams) => {
            notify.error(error.message);

            return onSubmitError(error, variables);
        },
    });

    const processing = useFileProcessing();

    useEffect(() => {
        if (isSuccess && processing) {
            setWaitFinishProcessing(true);
        }
    }, [isSuccess, processing]);

    useEffect(() => {
        if (waitFinishProcessing && !processing) {
            onComplete();
        }
    }, [processing, waitFinishProcessing, onComplete]);

    function handleDrop(file: File) {
        setSelectedFile(file);
    }

    function handleConfirm() {
        submit({
            forceUpload,
            email,
            file: selectedFile!,
        });
    }

    function handleClear() {
        setSelectedFile(null);
    }

    if (isSuccess) {
        return (
            <Parent email={email}>
                <div className={cx("file-item-wrapper")}>
                    <FileItem
                        fileName={selectedFile?.name || ""}
                        progress={processing?.progress || 0}
                    />
                </div>
            </Parent>
        );
    }

    if (!selectedFile) {
        return (
            <Parent email={email}>
                <FileForm onDrop={handleDrop} />
            </Parent>
        );
    }

    if (selectedFile) {
        return (
            <Parent email={email}>
                <FileConfirm
                    isFileSubmitting={isLoading}
                    onConfirm={handleConfirm}
                    onClear={handleClear}
                />
            </Parent>
        );
    }

    return null;
}

const onSubmitSuccessFn = (data: SubmitFileSuccess, variables: SubmitFileParams): void => void 0;
const onSubmitErrorFn = (error: Error, variables: SubmitFileParams): void => void 0;
const onCompleteFn = (): void => void 0;

FileStep.defaultProps = {
    onSubmitSuccess: onSubmitSuccessFn,
    onSubmitError: onSubmitErrorFn,
    onComplete: onCompleteFn,
};

type Props = {
    forceUpload: boolean;
    email: string;
    onSubmitSuccess?: typeof onSubmitSuccessFn;
    onSubmitError?: typeof onSubmitErrorFn;
    onComplete?: typeof onCompleteFn;
} & typeof FileStep.defaultProps;

export default FileStep;
