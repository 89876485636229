import React, { CSSProperties } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import styles from "./DotSpinner.module.scss";

const cx: CX = classnames.bind(styles);

function DotSpinner(props: Props) {
    const { color } = props;

    return (
        <div className={cx("dot-spinner")}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((dot: number) => {
                return (
                    <div
                        key={dot}
                        style={{ "--color": color } as CSSProperties}
                        className={cx("dot")}
                    />
                );
            })}
        </div>
    );
}

DotSpinner.defaultProps = {
    color: COLOR["blue-main"],
};

type Props = {
    color?: CSSProperties["color"];
} & typeof DotSpinner.defaultProps;

export default DotSpinner;
