import React from "react";

import classnames from "classnames/bind";

import { FileItem, NotesPair } from "@VIEW/components/common";
import { EmailForm, EmailFormValues } from "@VIEW/components/forms";

import { useFileProcessing } from "@VIEW/hooks";

import styles from "./EmailStep.module.scss";

const cx: CX = classnames.bind(styles);

function EmailStep(props: Props) {
    const { defaultEmail, defaultForceUpload, onSubmit } = props;

    function handleSubmit(values: EmailFormValues) {
        onSubmit(values);
    }

    const processing = useFileProcessing();
    const shouldShowScheduledAt = processing && processing.scheduledAt !== null;

    return (
        <div className={cx("email-step")}>
            <div className={cx("form-wrapper")}>
                {shouldShowScheduledAt && <div className={cx("note")}>Scheduled At: {processing.scheduledAt}</div>}

                <EmailForm
                    defaultEmail={defaultEmail}
                    defaultForceUpload={defaultForceUpload}
                    disabled={!!processing}
                    onSubmit={handleSubmit}
                />

                <div className={cx("file-wrapper")}>
                    {processing && !processing.isFinished && (
                        <>
                            <FileItem
                                fileName={processing.fileName}
                                progress={processing.progress}
                            />
                            <NotesPair />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

const onSubmitFn = (values: EmailFormValues): void => void 0;

type Props = {
    defaultEmail: string;
    defaultForceUpload: boolean;
    onSubmit: typeof onSubmitFn;
};

export default EmailStep;
