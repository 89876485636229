import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { Notificator } from "@VIEW/components/common";

import MainPage from "@VIEW/pages/MainPage";
import StatusPage from "@VIEW/pages/StatusPage";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={<MainPage />}
                    />
                    <Route
                        path="/status"
                        element={<StatusPage />}
                    />
                </Routes>
            </Router>

            <Notificator />
        </QueryClientProvider>
    );
}

export default App;
