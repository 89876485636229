import { API_PREFIX, BASE_URL } from "@CORE/constants";

export type CurrentJob = {
    created_at: string;
    id: number;
    is_finished: boolean;
    updated_at: string;
    total: number;
    progress: number;
    initiated_by: string;
};

export type IgnoredField = {
    id: number;
    site_id: number;
    fields: string;
};

export type GetIgnoredFieldsListSuccess = {
    current: CurrentJob | null;
    ignore_list: IgnoredField[];
};

export type GetIgnoredFieldsListError = {
    // TODO error
};

export async function getIgnoredFieldsListFn() {
    const response = await fetch(BASE_URL + API_PREFIX + "/index");

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}
