import { inIframe } from "@UTILS/helpers/in-iframe.helpers";

function createHook() {
    return function useIframe() {
        return {
            isIframe: inIframe(),
        };
    };
}

export const useIframe = createHook();
