import React from "react";

import ReactDOM from "react-dom/client";

import "normalize.css";
import "the-new-css-reset/css/reset.css";

import "@VIEW/styles/global.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

reportWebVitals();
