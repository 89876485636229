import React from "react";

import classnames from "classnames/bind";

import styles from "./LoginView.module.scss";

const cx: CX = classnames.bind(styles);

function LoginView() {
    return (
        <div className={cx("login-view")}>
            <div className={cx("message")}>Not authenticated</div>
        </div>
    );
}

export default LoginView;
