import { useMemo } from "react";

import { ToastOptions, toast } from "react-toastify";

function createHook() {
    return function useNotifications() {
        const notify = useMemo(
            () => ({
                info: (message: string, options?: ToastOptions) => {
                    toast.info(message, options);
                },
                warn: (message: string, options?: ToastOptions) => {
                    toast.warn(message, options);
                },
                error: (message: string, options?: ToastOptions) => {
                    toast.error(message, options);
                },
            }),
            [],
        );

        return {
            notify,
        };
    };
}

export const useNotifications = createHook();
