import React from "react";

import classnames from "classnames/bind";

import { Note } from "@VIEW/components/common";

import styles from "./NotesPair.module.scss";

const cx: CX = classnames.bind(styles);

function NotesPair() {
    return (
        <div className={cx("notes-pair")}>
            <Note title="Upload will proceed even if you close window" />
            <Note title="You may upload only one file" />
        </div>
    );
}

export default NotesPair;
