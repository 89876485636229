import React from "react";

import classnames from "classnames/bind";

import { Home } from "@VIEW/components/common";

import styles from "./MainPage.module.scss";

const cx: CX = classnames.bind(styles);

function MainPage() {
    return (
        <div className={cx("page")}>
            <Home />
        </div>
    );
}

export default MainPage;
