import React, { useId } from "react";

import classnames from "classnames/bind";

import { ReactComponent as CheckIcon } from "@ICONS/checkbox-icon.svg";

import { COLOR } from "@CORE/constants";

import styles from "./BasicCheckbox.module.scss";

const cx: CX = classnames.bind(styles);

function BasicCheckbox({
    checked, //
    disabled,
    onChange,
    renderLabel,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.checked, event);
        }
    }

    return (
        <div className={cx("basic-checkbox")}>
            <label
                htmlFor={inputId}
                className={cx({
                    disabled,
                })}
            >
                <input
                    id={inputId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    {...attributes}
                />
                <div className={cx("checkbox-wrapper")}>
                    {checked && (
                        <CheckIcon
                            fill={disabled ? COLOR["grey"] : COLOR["white"]}
                            height={12}
                            width={12}
                        />
                    )}
                </div>
                <div
                    style={{
                        color: disabled ? COLOR["grey"] : checked ? COLOR["blue-main"] : COLOR["grey"],
                    }}
                    className={cx("label-text")}
                >
                    {renderLabel(checked, disabled || false)}
                </div>
            </label>
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    checked: boolean;
    onChange: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
    renderLabel: (checked: boolean, disabled: boolean) => React.ReactNode;
}

export default BasicCheckbox;
