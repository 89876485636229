import React from "react";

import classnames from "classnames/bind";

import { ReactComponent as FileIcon } from "@ICONS/file-icon.svg";

import styles from "./FileItem.module.scss";

const cx: CX = classnames.bind(styles);

function getPercents(progress: number) {
    if (progress < 0) return 0;
    if (progress > 100) return 100;

    return progress;
}

function FileItem(props: Props) {
    const { progress, fileName } = props;

    const percents = getPercents(progress);

    return (
        <div className={cx("file-item")}>
            <div className={cx("file-name-wrapper")}>
                <div className={cx("file-icon")}>
                    <FileIcon />
                </div>
                <div className={cx("name")}>{fileName}</div>
                <div className={cx("percents")}>{`${percents}%`}</div>
            </div>
            <div className={cx("processing-wrapper")}>
                <div className={cx("track")}>
                    <div
                        className={cx("progress")}
                        style={{
                            width: `${percents}%`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

type Props = {
    fileName: string;
    progress: number;
};

export default FileItem;
