import React from "react";

import classnames from "classnames/bind";

import { ReactComponent as InfoIcon } from "@ICONS/info-icon.svg";

import styles from "./Note.module.scss";

const cx: CX = classnames.bind(styles);

// Upload will proceed even if you close window

function Note(props: Props) {
    const { title } = props;

    return (
        <div className={cx("note")}>
            <div className={cx("icon")}>
                <InfoIcon />
            </div>
            <div className={cx("text")}>{title}</div>
        </div>
    );
}

type Props = {
    title: string;
};

export default Note;
