import React from "react";

import classnames from "classnames/bind";

import styles from "./ApiMessage.module.scss";

const cx: CX = classnames.bind(styles);

function ApiMessage() {
    return (
        <div className={cx("api-message")}>
            Post request to <div className={cx("code")}>/api/v1/ignore</div> to add fields to be ignored
        </div>
    );
}

export default ApiMessage;
