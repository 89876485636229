import React, { ChangeEvent, InputHTMLAttributes, useId } from "react";

import classnames from "classnames/bind";

import styles from "./BasicInput.module.scss";

const cx: CX = classnames.bind(styles);

function BasicInput(props: Props) {
    const { value, onChange, label, type, ...rest } = props;

    const inputId = useId();

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
    }

    return (
        <div
            className={cx("basic-input", {
                error: type === "error",
            })}
        >
            <label htmlFor={inputId}>
                {label && <div className={cx("input-label")}>{label}</div>}

                <input
                    id={inputId}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    {...rest}
                />
            </label>
        </div>
    );
}

BasicInput.defaultProps = {
    label: "",
    type: "default",
};

type Props = {
    value: string;
    onChange: (value: string) => void;
    label?: string;
    type?: "default" | "error";
} & typeof BasicInput.defaultProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

export default BasicInput;
