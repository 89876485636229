import { useIframe } from "@VIEW/hooks/useIframe";
import { useLayoutEffect, useState } from "react";
import { z } from "zod";

const schema = z.object({
    name: z.literal("oasis-current-user"),
    email: z.string().trim(),
});

function createHook() {
    return function useCurrentUser() {
        const [email, setEmail] = useState<string | null>(null);
        const [isAuthentication, setAuthentication] = useState(true);

        const { isIframe } = useIframe();

        useLayoutEffect(() => {
            if (isIframe) {
                const handler = (event: MessageEvent<unknown>) => {
                    const parsed = schema.safeParse(event.data);

                    if (parsed.success) {
                        const { email: userEmail } = parsed.data;

                        setEmail(userEmail);
                        setAuthentication(false);
                    }
                };

                window.addEventListener("message", handler, false);
                window.parent.postMessage("oasis-request-current-user", {
                    targetOrigin: "*",
                });

                return () => {
                    window.removeEventListener("message", handler, false);
                };
            }

            setAuthentication(false);
        }, [isIframe]);

        return {
            email,
            isAuthentication,
        };
    };
}

export const useCurrentUser = createHook();
