import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { GetProgressError, GetProgressSuccess, getProgressFn, isProgressSuccessWithData } from "@API/queries";

function createHook() {
    return function useFileProcessing() {
        const { data } = useQuery<GetProgressSuccess, GetProgressError>({
            queryKey: ["progress"],
            queryFn: getProgressFn,
            refetchInterval: 5000,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
        });

        return useMemo(() => {
            if (!data) return null;

            if (!isProgressSuccessWithData(data)) return null;

            return {
                fileName: data.fileName,
                progress: data.progress,
                isFinished: data.isFinished,
                scheduledAt: data.scheduledAt,
            };
        }, [data]);
    };
}

export const useFileProcessing = createHook();
