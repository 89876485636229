import React from "react";

import classnames from "classnames/bind";

import {useFileProcessing} from "@VIEW/hooks";
import styles from "./Report.module.scss";

const cx: CX = classnames.bind(styles);

function Report(props: Props) {
    const {email} = props;
    const processing = useFileProcessing();
    const shouldShowScheduledAt = processing && processing.scheduledAt !== null;

    return (
        <div>
            <div className={cx("report")}>
                {shouldShowScheduledAt && (
                    <div className={cx("title")}>
                        Scheduled At: {processing.scheduledAt}
                    </div>
                )}
            </div>

            <div className={cx("report")}>
                <div className={cx("title")}>Report will be sent to</div>
                <div className={cx("email")}>{email}</div>
            </div>
        </div>
    );
}

type Props = {
    email: string;
};

export default Report;
