import React from "react";

import classnames from "classnames/bind";

import { DropFileInput } from "@VIEW/components/basic/inputs/file";

import styles from "./FileForm.module.scss";

const cx: CX = classnames.bind(styles);

function FileForm(props: Props) {
    const { onDrop } = props;

    return (
        <div className={cx("file-form")}>
            <DropFileInput
                title="Drag and drop CSV file"
                onDrop={(acceptedFiles: File[]) => {
                    if (acceptedFiles.length > 0) {
                        onDrop(acceptedFiles[0]);
                    }
                }}
            />
        </div>
    );
}

const onDropFn = (file: File): void => void 0;

type Props = {
    onDrop: typeof onDropFn;
};

export default FileForm;
