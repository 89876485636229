import React from "react";

import classnames from "classnames/bind";

import { ReactComponent as CheckCircleIcon } from "@ICONS/check-circle-icon.svg";
import { ReactComponent as XIcon } from "@ICONS/x-icon.svg";

import { MainButton } from "@VIEW/components/basic/buttons";
import { DotSpinner } from "@VIEW/components/basic/spinners";
import { BorderedFileContainer } from "@VIEW/components/common";

import styles from "./FileConfirm.module.scss";

const cx: CX = classnames.bind(styles);

function FileConfirm(props: Props) {
    const { isFileSubmitting, onConfirm, onClear } = props;

    function handleConfirm() {
        onConfirm();
    }

    function handleClear() {
        if (!isFileSubmitting) {
            onClear();
        }
    }

    return (
        <div className={cx("file-confirm")}>
            <BorderedFileContainer>
                <div className={cx("content")}>
                    <div>
                        <CheckCircleIcon />
                    </div>
                    <div className={cx("title")}>Your file is ready to Upload!</div>
                    <div className={cx("button-wrapper")}>
                        {isFileSubmitting ? (
                            <div className={cx("spinner-wrapper")}>
                                <DotSpinner />
                            </div>
                        ) : (
                            <MainButton
                                title="Start Uploading"
                                onClick={handleConfirm}
                            />
                        )}
                    </div>

                    <div className={cx("clear-button-wrapper")}>
                        <button
                            type="button"
                            className={cx("clear-button")}
                            onClick={handleClear}
                        >
                            <XIcon />
                        </button>
                    </div>
                </div>
            </BorderedFileContainer>
        </div>
    );
}

const onConfirmFn = (): void => void 0;
const onClearFn = (): void => void 0;

type Props = {
    isFileSubmitting: boolean;
    onConfirm: typeof onConfirmFn;
    onClear: typeof onClearFn;
};

export default FileConfirm;
