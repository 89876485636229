import React, { ButtonHTMLAttributes } from "react";

import classnames from "classnames/bind";

import styles from "./MainButton.module.scss";

const cx: CX = classnames.bind(styles);

function MainButton(props: Props) {
    const { title, disabled, onClick, ...rest } = props;

    function handleClick() {
        if (!disabled) {
            onClick();
        }
    }

    return (
        <button
            type="button"
            className={cx("main-button")}
            onClick={handleClick}
            disabled={disabled}
            {...rest}
        >
            {title}
        </button>
    );
}

type Props = {
    title: string;
    onClick: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default MainButton;
