import React from "react";

import classnames from "classnames/bind";

import styles from "./AuthenticationView.module.scss";

const cx: CX = classnames.bind(styles);

function AuthenticationView() {
    return (
        <div className={cx("authentication-view")}>
            <div className={cx("message")}>Authentication...</div>
        </div>
    );
}

export default AuthenticationView;
