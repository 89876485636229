import React, { useEffect, useState } from "react";

import classnames from "classnames/bind";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ReactComponent as DeleteIcon } from "@ICONS/delete-icon.svg";

import {
    DeleteIgnoredFieldError,
    DeleteIgnoredFieldParams,
    DeleteIgnoredFieldSuccess,
    deleteIgnoredFieldFn,
} from "@API/mutations";
import {
    CurrentJob,
    GetIgnoredFieldsListError,
    GetIgnoredFieldsListSuccess,
    IgnoredField,
    getIgnoredFieldsListFn,
} from "@API/queries";

import { DotSpinner } from "@VIEW/components/basic/spinners";
import { ApiMessage } from "@VIEW/components/common";

import styles from "./IgnoreList.module.scss";

const cx: CX = classnames.bind(styles);

function IgnoreList() {
    const [currentJob, setCurrentJob] = useState<CurrentJob | null>(null);

    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery<GetIgnoredFieldsListSuccess, GetIgnoredFieldsListError>({
        queryKey: ["ignored-fields-list"],
        queryFn: getIgnoredFieldsListFn,
        refetchOnWindowFocus: false,
    });

    const {
        mutate: deleteField,
        isLoading: isDeleting,
        isError,
    } = useMutation<DeleteIgnoredFieldSuccess, DeleteIgnoredFieldError, DeleteIgnoredFieldParams>({
        mutationFn: deleteIgnoredFieldFn,
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ["ignored-fields-list"] });
        },
        onError: () => {
            // TODO if error
        },
    });

    const ignoreList = data?.ignore_list || [];

    useEffect(() => {
        if (data?.current) {
            setCurrentJob(data.current);
        }
    }, [data]);

    function handleDelete(id: number) {
        deleteField({
            id,
        });
    }

    if (isError) {
        // TODO if error
    }

    return (
        <div className={cx("ignore-list")}>
            <ApiMessage />

            <div className={cx("table-wrapper")}>
                <div className={cx("description")}>Following fields from gd project id will be ignored</div>

                <table>
                    <thead>
                        <tr>
                            <th>
                                <div className={cx("header-cell")}>
                                    <div className={cx("title")}>GD Project Id</div>
                                </div>
                            </th>
                            <th>
                                <div className={cx("header-cell")}>
                                    <div className={cx("title")}>Field</div>
                                </div>
                            </th>
                            <th />
                        </tr>
                        <tr className={cx("spacer")} />
                    </thead>
                    <tbody>
                        {ignoreList.map((item: IgnoredField, i: number) => (
                            <React.Fragment key={item.id}>
                                <tr>
                                    <td>
                                        <div className={cx("column-cell")}>{item.site_id}</div>
                                    </td>
                                    <td>
                                        <div className={cx("column-cell", "field-cell")}>{item.fields}</div>
                                    </td>
                                    <td>
                                        <div className={cx("delete-cell")}>
                                            <button
                                                type="button"
                                                className={cx("delete-icon")}
                                                onClick={() => handleDelete(item.id)}
                                            >
                                                <DeleteIcon />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {i < ignoreList.length - 1 && <tr className={cx("spacer")} />}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                {isLoading && (
                    <div className={cx("spinner-wrapper")}>
                        <DotSpinner />
                    </div>
                )}
            </div>
        </div>
    );
}

export default IgnoreList;
