import { API_PREFIX, BASE_URL } from "@CORE/constants";

export type SubmitFileParams = {
    forceUpload: boolean;
    email: string;
    file: File;
};

export type SubmitFileSuccess = {
    data: number;
};

export type SubmitFileError = {
    detail: string;
};

export async function submitFileFn(params: SubmitFileParams) {
    const formData = new FormData();

    formData.append("force_upload", String(params.forceUpload));
    formData.append("email", params.email);
    formData.append("file", params.file);

    const response = await fetch(BASE_URL + API_PREFIX + "/upload", {
        method: "POST",
        body: formData,
    });

    if (!response.ok) {
        const message = ((await response.json()) as SubmitFileError)?.detail;

        if (message) {
            throw new Error(message);
        }

        throw new Error("Unexpected error");
    }

    return response.json();
}
