import React from "react";

import classnames from "classnames/bind";

import styles from "./StatusPage.module.scss";

const cx: CX = classnames.bind(styles);

// TODO unused
function StatusPage() {
    const handleStatusUpdate = (data: unknown) => {
        // Do something with the data received from the StatusComponent
        console.log("Status updated:", data);
    };

    return <div className={cx("page")}>{/* <StatusComponent onStatusUpdate={handleStatusUpdate} /> */}</div>;
}

export default StatusPage;
